<template>
	<div class="z-10 w-full flex flex-col bg-wemotooBlack-600">
		<div class="py-12 px-4 sm:px-20">
			<div class="grid grid-cols-1 md:grid-cols-4 items-stretch gap-0 sm:gap-6">
				<!-- Address Start -->
				<div class="flex flex-col text-white">
					<NuxtLink to="/">
						<img class="w-[150px] md:w-[180px]" src="../../assets/logo/logo.png" alt="logo" />
					</NuxtLink>
					<p class="text-white font-jakarta py-4 w-[80%] text-base">Floor D 20-08, Menara Mitraland 13A, Jln PJU 5/1, Kota Damansara, 47810, Petaling Jaya</p>
				</div>
				<!-- Address End -->

				<!-- Event Start -->
				<div class="flex flex-col text-white mt-4 md:mt-0">
					<p class="font-bold font-jakarta pb-3 text-lg">Events</p>
					<ul class="text-wemotooGrey-300 font-jakarta text-base">
						<li>
							<NuxtLink to="/user/events/">My Events</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/user/payment-history">Payment History</NuxtLink>
						</li>
					</ul>
				</div>
				<!-- Event End -->

				<!-- Sitemap Start -->
				<div class="flex flex-col text-white mt-4 md:mt-0">
					<p class="font-bold font-jakarta pb-3 text-lg">Wemotoo</p>
					<ul class="text-wemotooGrey-300 font-jakarta text-base">
						<li>
							<NuxtLink to="/about-us">About us</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/knowledge">Knowledge Center</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/merchant-nearby">Merchant Nearby</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/terms-and-conditions">Terms & Conditions</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/privacy-policy">Privacy Policy</NuxtLink>
						</li>
						<li>
							<NuxtLink to="/faq">FAQ</NuxtLink>
						</li>
					</ul>
				</div>
				<!-- Sitemap End -->

				<!-- Follow Us Start -->
				<div class="flex flex-col text-white mt-4 md:mt-0">
					<p class="font-bold font-jakarta pb-3 text-lg">Follow Us</p>
					<div class="flex">
						<NuxtLink class="pr-4" to="https://www.instagram.com/wemotoo/" target="_blank">
							<img src="../../assets/svg/instagram.svg" sizes="1vw" />
						</NuxtLink>
						<NuxtLink to="https://www.facebook.com/wemotoo/" target="_blank">
							<img src="../../assets/svg/facebook.svg" sizes="1vw" />
						</NuxtLink>
					</div>

					<!-- Contact Us Start -->
					<div class="flex flex-col text-white mt-8 md:mt-8 font-jakarta">
						<p class="font-bold pb-3 text-lg">Contact Us</p>
						<div class="flex">
							<img class="w-[16px]" src="../../assets/svg/phone.svg" alt="phone" />
							<NuxtLink href="tel:017-7179262" class="mx-2 text-white">017-7179262 (Ally)</NuxtLink>
						</div>
					</div>
					<!-- Contact Us End -->
				</div>
				<!-- Follow Us End -->
			</div>
		</div>

		<!-- copyright -->
		<div class="text-center bg-black py-2">
			<p class="font-jakarta text-wemotooGrey-300 text-sm px-4">
				©Copyright @ 2024 Wemotoo Capitals Berhad (Registration No.: 202201024524 (1470221-T)). All rights reserved.
			</p>
		</div>
		<!-- copyright -->
	</div>
</template>

<script lang="ts" setup></script>

<style scoped>
ul li {
	padding-bottom: 6px;
}
</style>
