import { defineStore } from 'pinia';
import locationService from '../utils/location-service';
import type { OrderDetailsReq, OrdersReq, UpdateUserReq } from '../repository/types/request';
import type { EventsReq } from '../repository/types/request/events.request';
import type { IApiInstance } from '../types/plugins';
import type { User, LatLong, Order, WemotooEvent } from '../types';

export const useUserStore = defineStore({
	id: 'userStore',
	state: () => ({
		user: null as User | null,
		location: {
			latitude: 0,
			longitude: 0,
		} as LatLong,
		loading: false as boolean,
		error: '',
		orders: [] as Order[],
		upcomingEvents: [] as WemotooEvent[],
		endedEvents: [] as WemotooEvent[],
	}),
	actions: {
		setUser(user: User | null) {
			this.user = user;
		},
		clearUser() {
			this.user = null;
		},
		// fetch user
		async fetchUser() {
			if (import.meta.client) {
				this.error = '';
				const authStore = useAuthStore();
				if (authStore.accessToken == null || authStore.accessToken == undefined || authStore.accessToken == '') {
					this.error = 'Please log in';
					return;
				}
				try {
					this.loading = true;

					const $api = useNuxtApp().$api as IApiInstance;
					const response = await $api.user.getUserProfile();

					// const response = await new ApiService().fetchUserProfileApi(authStore.accessToken!);
					if (response != undefined && response) {
						this.user = response;
					}
					return true;
				} catch (e) {
					console.error(e);
					this.error = JSON.stringify(e);
					return false;
				} finally {
					this.loading = false;
				}
			}
		},
		// update user
		async updateUser(user: UpdateUserReq) {
			// update user from api
			if (import.meta.client) {
				this.error = '';
				const authStore = useAuthStore();
				if (!authStore.accessToken) {
					this.error = 'Please log in';
					return false;
				}
				try {
					this.loading = true;

					const $api = useNuxtApp().$api as IApiInstance;
					const updateUserReq: UpdateUserReq = {
						name: user.name,
						address: user.address,
						date_of_birth: user.date_of_birth,
						ic_no: user.ic_no,
						license_expiry_date: this.user?.license_expiry_date,
					};

					const response = await $api.user.updateUserProfile(updateUserReq);

					if (response) {
						this.user = response;
						return true;
					}
				} catch (e) {
					console.error(e);
					this.error = JSON.stringify(e);
					return false;
				} finally {
					this.loading = false;
				}
			}
		},
		// delete user
		async deleteUser() {
			// delete user from api
		},
		// create user
		async createUser() {
			// create user from api
		},

		async getUserLocation() {
			const _locService = locationService();

			const _latLng = await _locService();

			if (_latLng != null) {
				this.location = _latLng;
			}
		},

		async getUserUpcomingEvents(skip: string | null = null) {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const eventsReq: EventsReq = {
					skip: skip ?? this.upcomingEvents.length.toString(),
					take: '6'.toString(),
					lat: '0',
					lng: '0',
					joined: '1',
					type: 'upcoming',
				};

				const response = await $api.event.getUserEvents(eventsReq);

				if (response != undefined && response) {
					this.upcomingEvents = response;
				}
				return true;
			} catch (e) {
				console.error(e);
				this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},

		async getUserEndedEvents(skip: string | null = null) {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const eventsReq: EventsReq = {
					skip: skip ?? this.endedEvents.length.toString(),
					take: '6'.toString(),
					lat: '0',
					lng: '0',
					joined: '1',
					type: 'ended',
				};

				const response = await $api.event.getUserEvents(eventsReq);

				if (response != undefined && response) {
					this.endedEvents = response;
				}
				return true;
			} catch (e) {
				console.error(e);
				this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},

		async getUserOrders(skip: number = 0) {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const ordersReq: OrdersReq = {
					skip: skip.toString(),
					take: '10'.toString(),
				};
				const response = await $api.order.getOrderHistories(ordersReq);

				if (skip == 0) {
					this.orders = response || [];
				} else {
					this.orders.push(...(response || []));
				}
				return true;
			} catch (e) {
				console.error(e);
				this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},

		async getOrderDetailById(id: number) {
			try {
				this.loading = true;

				if (!this.orders.find((o) => o.id == id)?.product) {
					const $api = useNuxtApp().$api as IApiInstance;
					const orderDetailsReq: OrderDetailsReq = { id };
					const response = await $api.order.getOrderDetails(orderDetailsReq);

					if (response != undefined && response) {
						const order = this.orders.find((o) => o.id == id);
						if (order) {
							order.product = response.product;
							order.event_id = response.event_id;
						}
					}
				}
			} catch (e) {
				console.error(e);
				this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},
	},
});
