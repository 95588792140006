import { default as indexLUGaUqXnpyMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/about-us/index.vue?macro=true";
import { default as indexI1BU0Xg91MMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/index.vue?macro=true";
import { default as participants_45detailsrjyhLOiTMQMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/participants-details.vue?macro=true";
import { default as purchase_45details04nOHeRQbmMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/purchase-details.vue?macro=true";
import { default as indexhEwhMojji9Meta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/index.vue?macro=true";
import { default as indexauLp51nlyDMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/faq/index.vue?macro=true";
import { default as indexgKSMZ1SBO6Meta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/index.vue?macro=true";
import { default as indexJHW0XvFVHBMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/knowledge/[id]/index.vue?macro=true";
import { default as indexzga7CkJ9XLMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/knowledge/index.vue?macro=true";
import { default as loginvcHZD5wvZpMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/login.vue?macro=true";
import { default as indexAOyEfsxaKJMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/merchant-nearby/[id]/index.vue?macro=true";
import { default as index2xIV1l4Nz5Meta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/merchant-nearby/index.vue?macro=true";
import { default as payment_45failQqvzRdEjJuMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-fail.vue?macro=true";
import { default as payment_45statusUHqfWrnqWrMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-status.vue?macro=true";
import { default as payment_45success7cQdkMUCrfMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-success.vue?macro=true";
import { default as indexbimjQiaBfiMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/privacy-policy/index.vue?macro=true";
import { default as registerqSbAKiFET6Meta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/register.vue?macro=true";
import { default as indexBXmyUeYyrIMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/terms-and-conditions/index.vue?macro=true";
import { default as ticketsEIWzhKwhlmMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/events/[id]/tickets.vue?macro=true";
import { default as indexBor7B7BJiMMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/events/index.vue?macro=true";
import { default as ticketsV0olWOteLeMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/order/[id]/tickets.vue?macro=true";
import { default as payment_45historyywmVMT3AuZMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/payment-history.vue?macro=true";
import { default as profileDjTGB8dQCGMeta } from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/profile.vue?macro=true";
export default [
  {
    name: indexLUGaUqXnpyMeta?.name ?? "about-us",
    path: indexLUGaUqXnpyMeta?.path ?? "/about-us",
    meta: indexLUGaUqXnpyMeta || {},
    alias: indexLUGaUqXnpyMeta?.alias || [],
    redirect: indexLUGaUqXnpyMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexI1BU0Xg91MMeta?.name ?? "events-id",
    path: indexI1BU0Xg91MMeta?.path ?? "/events/:id()",
    meta: indexI1BU0Xg91MMeta || {},
    alias: indexI1BU0Xg91MMeta?.alias || [],
    redirect: indexI1BU0Xg91MMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: participants_45detailsrjyhLOiTMQMeta?.name ?? "events-id-participants-details",
    path: participants_45detailsrjyhLOiTMQMeta?.path ?? "/events/:id()/participants-details",
    meta: participants_45detailsrjyhLOiTMQMeta || {},
    alias: participants_45detailsrjyhLOiTMQMeta?.alias || [],
    redirect: participants_45detailsrjyhLOiTMQMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/participants-details.vue").then(m => m.default || m)
  },
  {
    name: purchase_45details04nOHeRQbmMeta?.name ?? "events-id-purchase-details",
    path: purchase_45details04nOHeRQbmMeta?.path ?? "/events/:id()/purchase-details",
    meta: purchase_45details04nOHeRQbmMeta || {},
    alias: purchase_45details04nOHeRQbmMeta?.alias || [],
    redirect: purchase_45details04nOHeRQbmMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/[id]/purchase-details.vue").then(m => m.default || m)
  },
  {
    name: indexhEwhMojji9Meta?.name ?? "events",
    path: indexhEwhMojji9Meta?.path ?? "/events",
    meta: indexhEwhMojji9Meta || {},
    alias: indexhEwhMojji9Meta?.alias || [],
    redirect: indexhEwhMojji9Meta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexauLp51nlyDMeta?.name ?? "faq",
    path: indexauLp51nlyDMeta?.path ?? "/faq",
    meta: indexauLp51nlyDMeta || {},
    alias: indexauLp51nlyDMeta?.alias || [],
    redirect: indexauLp51nlyDMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexgKSMZ1SBO6Meta?.name ?? "index",
    path: indexgKSMZ1SBO6Meta?.path ?? "/",
    meta: indexgKSMZ1SBO6Meta || {},
    alias: indexgKSMZ1SBO6Meta?.alias || [],
    redirect: indexgKSMZ1SBO6Meta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJHW0XvFVHBMeta?.name ?? "knowledge-id",
    path: indexJHW0XvFVHBMeta?.path ?? "/knowledge/:id()",
    meta: indexJHW0XvFVHBMeta || {},
    alias: indexJHW0XvFVHBMeta?.alias || [],
    redirect: indexJHW0XvFVHBMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/knowledge/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzga7CkJ9XLMeta?.name ?? "knowledge",
    path: indexzga7CkJ9XLMeta?.path ?? "/knowledge",
    meta: indexzga7CkJ9XLMeta || {},
    alias: indexzga7CkJ9XLMeta?.alias || [],
    redirect: indexzga7CkJ9XLMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/knowledge/index.vue").then(m => m.default || m)
  },
  {
    name: loginvcHZD5wvZpMeta?.name ?? "login",
    path: loginvcHZD5wvZpMeta?.path ?? "/login",
    meta: loginvcHZD5wvZpMeta || {},
    alias: loginvcHZD5wvZpMeta?.alias || [],
    redirect: loginvcHZD5wvZpMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexAOyEfsxaKJMeta?.name ?? "merchant-nearby-id",
    path: indexAOyEfsxaKJMeta?.path ?? "/merchant-nearby/:id()",
    meta: indexAOyEfsxaKJMeta || {},
    alias: indexAOyEfsxaKJMeta?.alias || [],
    redirect: indexAOyEfsxaKJMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/merchant-nearby/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2xIV1l4Nz5Meta?.name ?? "merchant-nearby",
    path: index2xIV1l4Nz5Meta?.path ?? "/merchant-nearby",
    meta: index2xIV1l4Nz5Meta || {},
    alias: index2xIV1l4Nz5Meta?.alias || [],
    redirect: index2xIV1l4Nz5Meta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/merchant-nearby/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45failQqvzRdEjJuMeta?.name ?? "order-id-payment-fail",
    path: payment_45failQqvzRdEjJuMeta?.path ?? "/order/:id()/payment-fail",
    meta: payment_45failQqvzRdEjJuMeta || {},
    alias: payment_45failQqvzRdEjJuMeta?.alias || [],
    redirect: payment_45failQqvzRdEjJuMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-fail.vue").then(m => m.default || m)
  },
  {
    name: payment_45statusUHqfWrnqWrMeta?.name ?? "order-id-payment-status",
    path: payment_45statusUHqfWrnqWrMeta?.path ?? "/order/:id()/payment-status",
    meta: payment_45statusUHqfWrnqWrMeta || {},
    alias: payment_45statusUHqfWrnqWrMeta?.alias || [],
    redirect: payment_45statusUHqfWrnqWrMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-status.vue").then(m => m.default || m)
  },
  {
    name: payment_45success7cQdkMUCrfMeta?.name ?? "order-id-payment-success",
    path: payment_45success7cQdkMUCrfMeta?.path ?? "/order/:id()/payment-success",
    meta: payment_45success7cQdkMUCrfMeta || {},
    alias: payment_45success7cQdkMUCrfMeta?.alias || [],
    redirect: payment_45success7cQdkMUCrfMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/order/[id]/payment-success.vue").then(m => m.default || m)
  },
  {
    name: indexbimjQiaBfiMeta?.name ?? "privacy-policy",
    path: indexbimjQiaBfiMeta?.path ?? "/privacy-policy",
    meta: indexbimjQiaBfiMeta || {},
    alias: indexbimjQiaBfiMeta?.alias || [],
    redirect: indexbimjQiaBfiMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: registerqSbAKiFET6Meta?.name ?? "register",
    path: registerqSbAKiFET6Meta?.path ?? "/register",
    meta: registerqSbAKiFET6Meta || {},
    alias: registerqSbAKiFET6Meta?.alias || [],
    redirect: registerqSbAKiFET6Meta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexBXmyUeYyrIMeta?.name ?? "terms-and-conditions",
    path: indexBXmyUeYyrIMeta?.path ?? "/terms-and-conditions",
    meta: indexBXmyUeYyrIMeta || {},
    alias: indexBXmyUeYyrIMeta?.alias || [],
    redirect: indexBXmyUeYyrIMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: ticketsEIWzhKwhlmMeta?.name ?? "user-events-id-tickets",
    path: ticketsEIWzhKwhlmMeta?.path ?? "/user/events/:id()/tickets",
    meta: ticketsEIWzhKwhlmMeta || {},
    alias: ticketsEIWzhKwhlmMeta?.alias || [],
    redirect: ticketsEIWzhKwhlmMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/events/[id]/tickets.vue").then(m => m.default || m)
  },
  {
    name: indexBor7B7BJiMMeta?.name ?? "user-events",
    path: indexBor7B7BJiMMeta?.path ?? "/user/events",
    meta: indexBor7B7BJiMMeta || {},
    alias: indexBor7B7BJiMMeta?.alias || [],
    redirect: indexBor7B7BJiMMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/events/index.vue").then(m => m.default || m)
  },
  {
    name: ticketsV0olWOteLeMeta?.name ?? "user-order-id-tickets",
    path: ticketsV0olWOteLeMeta?.path ?? "/user/order/:id()/tickets",
    meta: ticketsV0olWOteLeMeta || {},
    alias: ticketsV0olWOteLeMeta?.alias || [],
    redirect: ticketsV0olWOteLeMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/order/[id]/tickets.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyywmVMT3AuZMeta?.name ?? "user-payment-history",
    path: payment_45historyywmVMT3AuZMeta?.path ?? "/user/payment-history",
    meta: payment_45historyywmVMT3AuZMeta || {},
    alias: payment_45historyywmVMT3AuZMeta?.alias || [],
    redirect: payment_45historyywmVMT3AuZMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/payment-history.vue").then(m => m.default || m)
  },
  {
    name: profileDjTGB8dQCGMeta?.name ?? "user-profile",
    path: profileDjTGB8dQCGMeta?.path ?? "/user/profile",
    meta: profileDjTGB8dQCGMeta || {},
    alias: profileDjTGB8dQCGMeta?.alias || [],
    redirect: profileDjTGB8dQCGMeta?.redirect,
    component: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/pages/user/profile.vue").then(m => m.default || m)
  }
]